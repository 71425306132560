/* istanbul ignore file */
import { useSnackbar } from 'react-simple-snackbar';

const fontFamily = 'Graphik, sans-serif';

const successOptions = {
  closeStyle: {
    outline: 'none'
  },
  position: 'top-center',
  style: {
    backgroundColor: '#F77A64',
    color: '#FFFFFF',
    fontFamily,
    marginTop: '35vh'
  }
};

const shopSuccessOptions = {
  closeStyle: {
    outline: 'none'
  },
  position: 'top-center',
  style: {
    backgroundColor: '#26443F',
    color: '#FFFFFF',
    fontFamily,
    marginTop: '35vh'
  }
};

const errorOptions = {
  closeStyle: {
    outline: 'none'
  },
  position: 'top-center',
  style: {
    backgroundColor: '#ED6B4D',
    color: '#FFFFFF',
    marginTop: '35vh',
    fontFamily
  }
};

export const TYPES = {
  SUCCESS: 'SUCCESS',
  SHOP_SUCCESS: 'SHOP_SUCCESS',
  ERROR: 'ERROR'
};

export default function useAlert(type = TYPES.SUCCESS, alertOptions?: any) {
  let options = successOptions;

  if (alertOptions) {
    options = alertOptions;
  }

  if (type === TYPES.ERROR) {
    options = errorOptions;
  }
  if (type === TYPES.SHOP_SUCCESS) {
    options = shopSuccessOptions;
  }

  const [openSnackbar] = useSnackbar(options);

  return openSnackbar;
}
