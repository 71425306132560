/* istanbul ignore file */

export enum ExperimentTitles {
  VIDEO_CONSULTATION_TEST = 'Video Consultation Test 2020-08',
  BRANCH_JOURNEY_BANNER_TEST = 'Branch Journey Banner Test',
  SCALING_JUMP_START = 'Scaling Jump Start 2021-03',
  CORE_EXPERIENCE_TEST_A = 'Core Experience 2.0 Test - Experimental Variation A',
  BANNER_PRICING_PAGE = 'Banner pricing page',
  CUSTOMER_ROOM_IDEAS_EXPERIENCE = 'Customer Room Ideas Experience',
  STYLE_QUIZ_QUESTION_FLOW = 'Style Quiz Experiment - Question Forward Flow',
  DESIGNER_CARD_CLICKABLE_EXPERIMENT = 'Designer Card Experiment - Larger clickable area',
  NEW_3D_STYLE_SURVEY_IMAGES = 'New 3D style survey images',
  MINI_PACKAGE_REMOVAL = 'Mini package removal',
  REFER_A_FRIEND_MODAL_CONCEPTS = 'Refer A Friend Modal - Concepts',
  REFER_A_FRIEND_MODAL_FINAL = 'Refer A Friend Modal - Final Concepts/Design',
  STYLE_QUIZ_HIP_UPDATES = 'Style Quiz HIP Updates',
  STYLE_QUIZ_INSPIRATION_IMAGES_HIP_UPDATES = 'Style Quiz Inspiration Images HIP Updates',
  BUDGET_FORWARD_ONBOARDING_FLOW = 'Budget-forward onboarding flow',
  HOMEPAGE_PRICING_CARDS_2023 = 'Homepage Pricing Cards 2023',
  HVC_HIGHER_BUDGET_RANGES = 'HVC Higher Budget Ranges',
  STYLE_SURVEY_IMAGERY_2024 = 'Style Survey Imagery 2024',
  ROOM_PROFILE_REDESIGN_2024 = 'Room Profile Redesign 2024'
}

export enum ExperimentVariationTitles {
  BRANCH_JOURNEY_BANNER_TEST_A = 'Branch Journey Banner Test - Experimental Variation A',
  CORE_EXPERIENCE_TEST_A = 'Core Experience 2.0 Test - Experimental Variation A',
  CUSTOMER_ROOM_IDEAS_TEST_A = 'Customer Room Ideas Experience - Experimental Variation A',
  STYLE_QUIZ_QUESTION_FLOW_TEST_A = 'Style Quiz Experiment - Question Forward Flow - Variation A',
  DESIGNER_CARD_CLICKABLE_EXPERIMENT_TEST_A = 'Designer Card Experiment - Larger clickable area - Variation A',
  NEW_3D_STYLE_SURVEY_IMAGES_TEST_A = 'New 3D style survey images - Variation A',
  MINI_PACKAGE_REMOVAL_TEST_A = 'Mini package removal - Variation A',
  REFER_A_FRIEND_MODAL_CONCEPTS_TEST_A = 'Refer A Friend Modal Concepts - Experimental Variation A',
  REFER_A_FRIEND_MODAL_CONCEPTS_TEST_B = 'Refer A Friend Modal Concepts - Experimental Variation B',
  REFER_A_FRIEND_MODAL_FINAL_TEST_A = 'Refer A Friend Modal Final Concepts/Design - Experimental Variation A',
  REFER_A_FRIEND_MODAL_FINAL_TEST_B = 'Refer A Friend Modal Final Concepts/Design - Experimental Variation B',
  STYLE_QUIZ_HIP_UPDATES_TEST_A = 'Style Quiz HIP Updates - Experimental Variation A',
  STYLE_QUIZ_HIP_UPDATES_TEST_B = 'Style Quiz HIP Updates - Experimental Variation B',
  STYLE_QUIZ_HIP_UPDATES_CONTROL = 'Style Quiz HIP Updates - Control',
  STYLE_QUIZ_INSPIRATION_IMAGES_HIP_UPDATES_TEST_A = 'Style Quiz Inspiration Images HIP Updates - Experimental Variation A',
  STYLE_QUIZ_INSPIRATION_IMAGES_HIP_UPDATES_TEST_B = 'Style Quiz Inspiration Images HIP Updates - Control',
  BUDGET_FORWARD_ONBOARDING_CONTROL = 'Budget-forward onboarding flow - Control',
  BUDGET_FORWARD_ONBOARDING_TEST_A = 'Budget-forward onboarding flow - Experimental Variation A',
  HVC_HIGHER_BUDGET_RANGES_CONTROL = 'HVC Higher Budget Ranges - Control',
  HVC_HIGHER_BUDGET_RANGES_TEST_A = 'HVC Higher Budget Ranges - Experimental Variation A',
  STYLE_SURVEY_IMAGERY_2024_TEST_A = 'Style Survey Imagery 2024 - Experimental Variation A',
  STYLE_SURVEY_IMAGERY_2024_CONTROL = 'Style Survey Imagery 2024 - Control',
  ROOM_PROFILE_REDESIGN_2024_VARIATION_A = 'Room profile redesign 2024 variation A',

}
